/* eslint-disable */
if (!localStorage.getItem('search-and-bookmark-data')) {
  localStorage.setItem('search-and-bookmark-data', JSON.stringify({
    pages: {
      key: 'title',
      data: [
              ]
    },
  }))
}
// prettier-ignore
export default [
  ]
